<template>
  <div class="complete">
    <component :is="template" :timeout="timeoutForMySaldo"/>
    <span v-html="affiliate"/>
    <v-snackbar
      v-model="showSnackbarTimer"
      :timeout="-1"
      right
      bottom
    >
      <translate
        :translate-n="seconds"
        translate-plural="Redirecting to MySaldo in %{ seconds } seconds."
      >
        Redirecting to MySaldo in %{ seconds } second.
      </translate>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CwComplete',

  components: {
    'cw-car-loan-complete': () => import('@fi/pages/steps/CompleteCarLoan'),
    'cw-continuous-loan-complete': () => import('@fi/pages/steps/CompleteContinuousLoan'),
    'cw-instalment-loan-complete': () => import('@fi/pages/steps/CompleteInstalmentLoan'),
  },

  data: () => ({
    template: '',
    affiliate: null,
    counter: null,
    seconds: 0,
    showSnackbarTimer: false,
    timeoutForMySaldo: 15000,
  }),

  computed: {
    ...mapGetters({
      offer: 'application/getSelectedOffer',
    }),
  },

  async created() {
    const { type } = this.offer;

    this.setTemplate(type);

    if (type !== 'carInstalmentLoan') {
      this.startSnackbarTimer();
    }

    this.affiliate = await this.getAdTracking();

    /**
     * Set the purchase property to false to prevent duplicate purchase events in GA
     */
    this.setPurchase(false);
  },

  beforeDestroy() {
    clearInterval(this.counter);
  },

  methods: {
    ...mapActions({
      getAdTracking: 'application/getAdTracking',
      setPurchase: 'application/setPurchase',
    }),

    setTemplate(type) {
      if (!type) return;

      const offerTypes = {
        continuousLoan: 'cw-continuous-loan-complete',
        fixedPeriodContinuousLoan: 'cw-continuous-loan-complete',
        instalmentLoan: 'cw-instalment-loan-complete',
        carInstalmentLoan: 'cw-car-loan-complete',
      };

      this.template = offerTypes[type];
    },

    startSnackbarTimer() {
      this.showSnackbarTimer = true;
      this.seconds = this.timeoutForMySaldo / 1000;

      this.counter = setInterval(() => {
        this.seconds -= 1;

        if (this.seconds === 0) {
          clearInterval(this.counter);
          this.showSnackbarTimer = false;
        }
      }, 1000);
    },
  },
};
</script>
